<template>
    <div class="nsi-list">

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Перечень запросов на добавление НСИ</h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="outline-primary"
                    v-b-modal.import>Импортировать
                </BButton>
                <BButton
                    class="ml-3"
                    variant="primary"
                    to="/create-nsi"
                >Создать
                </BButton>
            </div>

        </div>

        <TableFilter
            :columns="positionsFields"
            @update-selected-columns="columns = $event"
            @search="filter = $event"
            @clear="clearFilterData"
            @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
        />

        <DefaultTable
            :data="nsiList"
            :fields="positionsFieldsFiltered"
            :filter="filter"
            :loading="loading"
            rowLink="/nsi-list"
            emptyText="Нет запросов на добавление НСИ"
            downloadFor="request-addition/nomenclature"
            :noLocalSorting="true"
            @sort-change="sorting"
            @pagination-change="paginationChange"
            @per-page-change="perPageChange"
        />

        <b-modal
            id="import"
            centered title="Импорт перечня запросов на добавление НСИ">

            <div class="p-2 bg-light">

                <div class="font-weight-bold text-muted">Файл для импорта <span class="text-danger">*</span></div>

                <b-form-file
                    v-model="importFile"
                    class="mt-1"
                    plain />

            </div>

            <template #modal-footer="{ hide }">
                <b-button variant="outline-primary" @click="hide()">
                    Отмена
                </b-button>
                <b-button
                    variant="primary"
                    @click="sendImport">
                    Импортировать
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="import-success"
            centered title="Импорт завершён">

            <div class="p-2 bg-light">

                <p>Импортирование файла «{{ importFile ? importFile.name : '' }}» завершено</p>

            </div>

            <template #modal-footer="{ hide }">
                <b-button
                    variant="primary"
                    @click="hide()">
                    Хорошо
                </b-button>
            </template>
        </b-modal>

        <FilterSidebar
            id="filter"
            v-model="filterData"
            @apply="updateDataWithFilters"
        />

    </div>
</template>

<script>

import { mapState } from 'vuex'
import TableFilter from '@/components/TableFilter'
import DefaultTable from "@/components/Tables/Default"
import FilterSidebar from "@/components/FilterSidebar"

function initialFilterData() {
    return {
        number: '',
        date: ['', ''],
        type: '',
        organization_status: ''
    }
}

export default {
    name: "NsiList",
    components: {
        TableFilter,
        DefaultTable,
        FilterSidebar
    },
    computed: {
        ...mapState({
            nsiList: state => state.nsi.nsiList
        }),
        positionsFieldsFiltered () {
            let res = []
            this.positionsFields.forEach(item => {
                if (this.columns.filter(col => col === item.label).length) {
                    res.push(item)
                }
            })
            return res.length > 0 ? res : this.positionsFields
        }
    },
    data: () => ({
        positionsFields: [
            {
                key: 'number',
                sortable: true,
                label: '№ запроса',
                full: '№ запроса',
                openDefault: true
            },
            {
                key: 'date',
                sortable: true,
                label: 'Дата запроса',
                full: 'Дата запроса',
                openDefault: true
            },
            {
                key: 'work_agreement_number',
                nesting: 'nested_work_agreement_number',
                sortable: true,
                label: '№ договора подряда/поставки',
                full: '№ договора подряда/поставки',
                openDefault: true
            },
            {
                key: 'work_agreement_date',
                nesting: 'nested_work_agreement_date',
                sortable: true,
                label: 'Дата договора подряда/поставки',
                full: 'Дата договора подряда/постастопвки',
                openDefault: true
            },
            {
                key: 'organization',
                nesting: 'unnested_organization',
                sortable: true,
                label: 'Филиал',
                full: 'Филиал',
                openDefault: true
            },
            {
                key: 'organization_status',
                sortable: true,
                label: 'Статус',
                full: 'Статус',
                openDefault: true
            },
            {
                key: 'nomenclature',
                nesting: 'nomenclature_name',
                sortable: true,
                label: 'Номенклатура',
                full: 'Номенклатура',
                openDefault: true
            },
            {
                key: 'nomenclature_unit',
                sortable: true,
                label: 'Единицы измерения',
                full: 'Единицы измерения',
                openDefault: true
            },
            {
                key: 'description',
                sortable: true,
                label: 'Характеристика / описание',
                full: 'Характеристика / описание',
                openDefault: true
            },
            {
                key: 'responsible_full_name',
                sortable: true,
                label: 'Ответственный',
                full: 'Ответственный',
                openDefault: true
            },
            {
                key: 'contr_agent_comment',
                sortable: true,
                label: 'Комментарий',
                full: 'Комментарий',
                openDefault: true
            },
            {
                key: 'organization_comment',
                sortable: true,
                label: 'Комментарий заказчика',
                full: 'Комментарий заказчика',
                openDefault: true
            },
            {
                key: 'file_url',
                sortable: true,
                label: 'Приложение',
                full: 'Приложение',
                openDefault: true
            }
        ],
        importFile: '',
        columns: [],
        filter: '',
        filterData: initialFilterData(),
        loading: false,
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Перечень запросов на добавление НСИ' }
        ],
        sortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        }
    }),
    methods: {
        async getData () {
            this.loading = true
            await this.$store.dispatch('getNsiList')
            this.loading = false
        },
        sendImport () {
            if (this.importFile !== null) {
                this.$bvModal.show('import-success')
                this.$bvModal.hide('import')
            } else {
                this.$bvToast.toast('Вам необходимо загрузить файл формата XML', {
                    title: 'Вы не прикрепили файл для импорта',
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
            }
        },
        clearFilterData () {
            this.filterData = initialFilterData()
            this.getData()
        },
        async updateDataWithFilters (clearSort) {
            this.loading = true
            if (clearSort) {
                this.sortingData.sort_field = ''
                this.sortingData.sort_order = ''
                this.sortingData.page = ''
            }
            await this.$store.dispatch('getNsiList', {
                ...this.filterData,
                ...this.sortingData
            })
            this.loading = false
        },
        sorting (event) {
            this.sortingData.sort_field = event.sortBy
            this.sortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updateDataWithFilters()
        },
        paginationChange (page) {
            this.sortingData.page = page
            this.updateDataWithFilters()
        },
        perPageChange (perPage) {
            this.sortingData.page = ''
            this.sortingData.per_page = perPage
            this.updateDataWithFilters()
        }
    },
    mounted () {
        this.getData()
        this.positionsFields.forEach(item => {
            if (item.openDefault) {
                this.columns.push(item.label)
            }
        })
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
